import Vue, {Component} from "vue" ;
import { BigBigWork, isLoginClient } from "bbw-common" ;
import { Store } from "vuex" ;
import Router from "vue-router" ;

const openPinterestDialog = ( () => {
    let pinterestInstance = null ;
    return async function openPinterestDialog ( store, appointUrl = `` ) {
        if ( pinterestInstance ) {
            pinterestInstance.isShow = true ;
            pinterestInstance.instanceIsShow = true ;
        } else {
            // 含 未绑定手机号
            // @ts-ignore
            // await import ( `../../../../node_modules/bbw-components/lib/assets/DialogPinterest.css` ) ;
            const dialogPinterest = await import ( `../DialogPinterest` ) ;
            pinterestInstance = new Vue ( {
                ...dialogPinterest.default,
                props: {
                    isShow: {
                        type: Boolean,
                        required: false,
                        default: false
                    }
                }
            } ) ;
            pinterestInstance.appointUrl = appointUrl ;
            pinterestInstance.$store = store ;
            const el = document.createElement ( `div` ) ;
            console.log ( `pinterestInstance`, pinterestInstance ) ;
            pinterestInstance.$mount ( el ) ;
            document.body.appendChild ( el ) ;
            pinterestInstance.$nextTick ().then ( _ => {
                pinterestInstance.isShow = true ;
            } ) ;
            pinterestInstance.$on ( `closeDialogPinterest`, ( ) => {
                pinterestInstance.isShow = false ;
                pinterestInstance.instanceShow = false ;
            } ) ;
        }
    } ;
} ) () ;
const openNotLoginPinterestClient = ( () => {
    let instanceDialog = null ;
    return async function openNotLoginPinterestClient ( store ) {
        if ( instanceDialog ) {
            // @ts-ignore
            instanceDialog.isShow = true ;
            // @ts-ignore
            instanceDialog.instanceIsShow = true ;
        } else {
            // @ts-ignore
            // await import ( `../../../../node_modules/bbw-components/lib/assets/DialogNotLoginClientPin.css` ) ;
            const dialogNotLoginClient = await import ( `../DialogNotLoginClientPin` ) ;
            instanceDialog = new Vue ( {
                ...dialogNotLoginClient.default,
                props: {
                    isShow: {
                        type: Boolean,
                        required: false,
                        default: true
                    }
                }
            } ) ;
            instanceDialog.$store = store ;
            const el = document.createElement ( `div` ) ;
            instanceDialog.$mount ( el ) ;
            document.body.appendChild ( el ) ;
            instanceDialog.$nextTick ().then ( _ => {
                // @ts-ignore
                instanceDialog.isShow = true ;
                // @ts-ignore
                instanceDialog.instanceIsShow = true ;
            } ) ;
            instanceDialog.$on ( `onClose`, ( ) => {
                instanceDialog.isShow = false ;
                instanceDialog.instanceShow = false ;
            } ) ;
        }
    } ;
} ) () ;
/**
 * @description 点击Pinterest校验
 * @param store
 * @constructor
 */
export const CheckPinterest = async ( store, vipDialogTitle: string, fromText: string, appointUrl?: string, fromType: number = 2 ) => {
    // await openPinterestDialog ( store ) ;
    // return;
    if ( !store.state.UserInfo.pinDays ) {
        createDialogVip ( {
            store,
            customTitle: vipDialogTitle,
            source: fromText,
            fromType
        } ) ;
        BigBigWork.emit ( `Analysis`, {
            type: `openPay`,
            data: {
                from_text: fromText,
                from_type: fromType,
                action_source: 1,
                u_id: store.state.UserInfo.id,
                user_type: store.state.UserInfo.userType
            }
        } ) ;
        return true ;
    } else if ( !store.state.UserInfo.phone ) {
        await openPinterestDialog ( store ) ;
        return true ;
    } else if ( !( await isLoginClient () ) ) {
        //    未登录过客户端
        await openNotLoginPinterestClient ( store ) ;
        return true ;
    } else {
        await openPinterestDialog ( store, appointUrl ) ;
        return true ;
    }
} ;
const openBehanceDialog = ( () => {
    let behanceInstance = null ;
    return async function openBehanceDialog ( store, appointUrl = `` ) {
        if ( !behanceInstance ) {
            // 含 未绑定手机号
            const dialogBehance = await require ( `../DialogBehance` ) ;
            behanceInstance = new Vue ( {
                ...dialogBehance.default,
                props: {
                    isShow: {
                        type: Boolean,
                        required: false,
                        default: false
                    }
                }
            } ) ;
            behanceInstance.appointUrl = appointUrl ;
            behanceInstance.$store = store ;
            const el = document.createElement ( `div` ) ;
            console.log ( `behanceInstance`, behanceInstance ) ;
            behanceInstance.$mount ( el ) ;
            document.body.appendChild ( el ) ;
            behanceInstance.$nextTick ().then ( _ => {
                behanceInstance.isShow = true ;
                behanceInstance.instanceIsShow = true ;
            } ) ;
            behanceInstance.$on ( `onClose`, ( ) => {
                behanceInstance.isShow = false ;
                behanceInstance.instanceShow = false ;
            } ) ;
        } else {
            behanceInstance.isShow = true ;
            behanceInstance.instanceIsShow = true ;
        }
    } ;
} ) () ;
const openNotLoginBehanceClient = ( () => {
    let instanceDialog = null ;
    return async function ( store ) {
        if ( !instanceDialog ) {
            // @ts-ignore
            // await import ( `../../../../node_modules/bbw-components/lib/assets/DialogNotLoginClientBehance.css` ) ;
            const dialogNotLoginClient = await import ( `../DialogNotLoginClientBehance` ) ;
            instanceDialog = new Vue ( {
                ...dialogNotLoginClient.default,
                props: {
                    isShow: {
                        type: Boolean,
                        required: false,
                        default: true
                    }
                }
            } ) ;
            instanceDialog.$store = store ;
            const el = document.createElement ( `div` ) ;
            instanceDialog.$mount ( el ) ;
            document.body.appendChild ( el ) ;
            instanceDialog.$nextTick ().then ( _ => {
                // @ts-ignore
                instanceDialog.isShow = true ;
                // @ts-ignore
                instanceDialog.instanceIsShow = true ;
            } ) ;
            instanceDialog.$on ( `onClose`, ( ) => {
                instanceDialog.isShow = false ;
                instanceDialog.instanceShow = false ;
            } ) ;
        } else {
            // @ts-ignore
            instanceDialog.isShow = true ;
            // @ts-ignore
            instanceDialog.instanceIsShow = true ;
        }
    } ;
} ) () ;
/**
 * @description 点击Behance校验
 * @param store
 * @constructor
 */
export const CheckBehance = async ( store, vipDialogTitle: string, source: string, fromType: number = 2, appointUrl?: string ) => {
    if ( !store.state.UserInfo.pinDays ) {
        createDialogVip ( {
            store,
            customTitle: vipDialogTitle,
            source: source
        } ) ;
        BigBigWork.emit ( `Analysis`, {
            type: `openPay`,
            data: {
                from_text: source,
                from_type: 2,
                action_source: 1,
                u_id: store.state.UserInfo.id,
                user_type: store.state.UserInfo.userType
            }
        } ) ;
        return true ;
    } else if ( !store.state.UserInfo.phone ) {
        await openBehanceDialog ( store ) ;
        return true ;
    } else if ( !( await isLoginClient () ) ) {
        //    未登录过客户端
        await openNotLoginBehanceClient ( store ) ;
        return true ;
    } else {
        await openBehanceDialog ( store, appointUrl ) ;
        return true ;
    }
} ;
/**
 * @description Artstation弹框
 */
export const openArtstation = ( () => {
    let artstationInstance = null ;
    return async function openArtstationDialog ( store, appointUrl = `` ) {
        if ( !artstationInstance ) {
            // 含 未绑定手机号
            // @ts-ignore
            // await import ( `../../../../node_modules/bbw-components/lib/assets/DialogArtstation.css` ) ;
            const dialogBehance = await import ( `../DialogArtstation` ) ;
            artstationInstance = new Vue ( {
                ...dialogBehance.default,
                props: {
                    isShow: {
                        type: Boolean,
                        required: false,
                        default: false
                    }
                }
            } ) ;
            artstationInstance.appointUrl = appointUrl ;
            artstationInstance.$store = store ;
            const el = document.createElement ( `div` ) ;
            console.log ( `artstationInstance`, artstationInstance ) ;
            artstationInstance.$mount ( el ) ;
            document.body.appendChild ( el ) ;
            artstationInstance.$nextTick ().then ( _ => {
                artstationInstance.isShow = true ;
            } ) ;
            artstationInstance.$on ( `onClose`, ( ) => {
                artstationInstance.isShow = false ;
                artstationInstance.instanceShow = false ;
            } ) ;
        } else {
            artstationInstance.isShow = true ;
            artstationInstance.instanceIsShow = true ;
        }
    } ;
} ) () ;

/**
 * @description Artstation弹框
 */
export const openNotLoginArtstationClient = ( () => {
    let instanceDialog = null ;
    return async function openNotLoginArtstationClient ( store ) {
        if ( !instanceDialog ) {
            // @ts-ignore
            // await import ( `../../../../node_modules/bbw-components/lib/assets/DialogNotLoginClientArtstation.css` ) ;
            const dialogNotLoginClient = await import ( `../DialogNotLoginClientArtstation` ) ;
            instanceDialog = new Vue ( {
                ...dialogNotLoginClient.default,
                props: {
                    isShow: {
                        type: Boolean,
                        required: false,
                        default: true
                    }
                }
            } ) ;
            instanceDialog.$store = store ;
            const el = document.createElement ( `div` ) ;
            instanceDialog.$mount ( el ) ;
            document.body.appendChild ( el ) ;
            instanceDialog.$nextTick ().then ( _ => {
                // @ts-ignore
                instanceDialog.isShow = true ;
                // @ts-ignore
                instanceDialog.instanceIsShow = true ;
            } ) ;
            instanceDialog.$on ( `onClose`, ( ) => {
                instanceDialog.isShow = false ;
                instanceDialog.instanceShow = false ;
            } ) ;
        } else {
            // @ts-ignore
            instanceDialog.isShow = true ;
            // @ts-ignore
            instanceDialog.instanceIsShow = true ;
        }
    } ;
} ) () ;

/**
 * @description 点击Artstation校验
 * @param store
 * @constructor
 */
export const CheckArtstation = async ( store, vipDialogTitle: string, source: string, fromType: number = 2, appointUrl?: string ) => {
    if ( !store.state.UserInfo.pinDays ) {
        await createDialogVip ( {
            store,
            customTitle: vipDialogTitle,
            source: source
        } ) ;
        BigBigWork.emit ( `Analysis`, {
            type: `openPay`,
            data: {
                from_text: source,
                from_type: 2,
                action_source: 1,
                u_id: store.state.UserInfo.id,
                user_type: store.state.UserInfo.userType
            }
        } ) ;
        return true ;
    } else if ( !store.state.UserInfo.phone ) {
        // await openArtstationDialog () ;
        await openArtstation ( store ) ;
        return true ;
    } else if ( !( await isLoginClient () ) ) {
        //    未登录过客户端
        await openNotLoginArtstationClient ( store ) ;
        return true ;
    } else {
        await openArtstation ( store, appointUrl ) ;
        return true ;
    }
} ;

/** 创建vip弹框 */
export const createDialogVip = ( ( ) => {
    let instanceDialogVip = null ;
    return async function ( options:{
        store:Store<any>,
        customTitle: string,
        source?: string,
        fromType?: number
    } ) {
        const title = options.customTitle ;
        if ( !instanceDialogVip ) {
            const dialogVip = await require ( `../DialogVip` ) ;
            instanceDialogVip = new Vue ( {
                ...dialogVip.default,
                props: {
                    ...dialogVip.default.props,
                    isShow: {
                        type: Boolean,
                        default: false,
                        require: false
                    },
                    // 自定义标题
                    customTitle: {
                        type: String,
                        default: title,
                        require: false
                    },
                    source: {
                        type: String,
                        default: options.source || `其他`,
                        require: false
                    },
                    fromType: {
                        type: Number,
                        default: options.fromType || 9,
                        require: false
                    }
                }
            } ) ;
            instanceDialogVip.$store = options.store ;
            const el = document.createElement ( `div` ) ;
            instanceDialogVip.$mount ( el ) ;
            document.body.appendChild ( el ) ;
            instanceDialogVip.$nextTick ().then ( res => {
                instanceDialogVip.isShow = true ;
            } ) ;
            instanceDialogVip.$on ( `onClose`, ( ) => {
                instanceDialogVip.isShow = false ;
                instanceDialogVip.instanceShow = false ;
                instanceDialogVip.source = `` ;
                instanceDialogVip.fromType = 9 ;
            } ) ;
        } else {
            // instanceDialogVip.customTitle = options.customTitle || `<span class="red">开通VIP</span>，3000万免费版权图片每天随意下！` ;
            instanceDialogVip.customTitle = options.customTitle ;
            instanceDialogVip.isShow = true ;
            instanceDialogVip.source = options.source || `其他` ;
            instanceDialogVip.fromType = options.fromType || 9 ;
            instanceDialogVip.instanceShow = true ;
        }
    } ;
} ) ( ) ;

export const openVipDialog = async ( options:{
    store:Store<any>,
    customTitle: string,
    source?: string,
    fromType?: number
} ) => {
    // 统计付费来源
    const msg = Object.assign (
        { from_text: options.source, from_type: options.fromType },
        {
            u_id: options.store.state.UserInfo.id,
            user_type: options.store.state.UserInfo.userType,
            action_source: 1
        }
    ) ;
    console.log ( `openVipDialog`, msg ) ;
    BigBigWork.emit ( `Analysis`, { type: `openPay`, data: msg } ) ;
    await createDialogVip ( options ) ;
} ;

/** 对话框事件 */
export type DialogCloseEvent = {
    type: string,
    data?: any
}

/** 显示对话框
 * @description 显示对话框, 通过动态加载组件的方式, 传入组件路径, 以及组件的propsData, 返回对话框的关闭事件
 * @param dialogComp 对话框组件
 * @param opts 配置
 * @returns Promise<DialogCloseEvent>
 */
export const showDialogAsync = async (
    dialogComp: Component<any>,
    opts?: {
        propsData?: { [key in string]: any }, // 对话框的propsData
        aniDuration?: number, // 动画时长
        store?: Store<any>, // vuex store
        router?: Router, // vue-router
        compName?: string, // 组件名称
        closeEventNames?: string[], // 关闭事件名称
        closedEventNames?: string[], // 关闭事件名称
        closeCallback?: ( resolve: ( value: DialogCloseEvent ) => void, event: DialogCloseEvent ) => void, // 关闭回调
        openCallback?: ( event: DialogCloseEvent ) => void, // 打开回调
        destroy?: boolean // 是否销毁实例
    }
) => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise ( async ( resolve, reject ) => {
        const {
            propsData = {},
            store = null,
            closeEventNames = [`close`],
            closedEventNames = [`closed`],
            compName = `Dialog-${new Date ().getTime ()}`,
            closeCallback = null,
            openCallback = null,
            destroy = true
        } = opts ?? {} ;
        // @ts-ignore
        const Dialog = Vue.extend ( dialogComp ) ;
        const instance = new Dialog ( {
            propsData: {
                show: true,
                ...propsData
            },
            store: store,
            name: compName
        } ) ;
        // 关闭事件, 通过closeEventNames来监听
        const handleClose = async ( event: DialogCloseEvent ) => {
            if ( closeCallback ) {
                return closeCallback ( resolve, event ) ;
            } else {
                return resolve ( event ) ;
            }
        } ;
        // 监听关闭事件
        for ( const closeEventName of closeEventNames ) {
            instance.$on ( closeEventName, ( e ) => {
                handleClose ( {
                    type: closeEventName,
                    data: e
                } ) ;
            } ) ;
        }
        // 监听动画关闭事件, 销毁实例
        const handleClosed = () => {
            if ( destroy ) {
                document.body.removeChild ( instance.$el ) ;
                instance.$destroy () ;
            }
        } ;
        // 监听关闭事件
        for ( const closedEventName of closedEventNames ) {
            instance.$on ( closedEventName, handleClosed ) ;
        }
        instance.$mount () ;
        document.body.appendChild ( instance.$el ) ;
        // 打开回调
        if ( openCallback ) {
            openCallback ( { type: `open`, data: instance } ) ;
        }
    } ) ;
} ;
export default {
    CheckArtstation,
    CheckBehance,
    CheckPinterest,
    openArtstation,
    openBehanceDialog,
    openNotLoginArtstationClient,
    openNotLoginBehanceClient,
    openNotLoginPinterestClient,
    openPinterestDialog,
    createDialogVip,
    openVipDialog,
    showDialogAsync
} ;
